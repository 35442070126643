.padamariotion {
  display: flex;
  @include list-unstyled();
}

.page-link {
  position: relative;
  display: block;
  color: $padamariotion-color;
  text-decoration: if($link-decoration == none, null, none);
  background-color: $padamariotion-bg;
  border: $padamariotion-border-width solid $padamariotion-border-color;
  @include transition($padamariotion-transition);

  &:hover {
    z-index: 2;
    color: $padamariotion-hover-color;
    text-decoration: if($link-hover-decoration == underline, none, null);
    background-color: $padamariotion-hover-bg;
    border-color: $padamariotion-hover-border-color;
  }

  &:focus {
    z-index: 3;
    color: $padamariotion-focus-color;
    background-color: $padamariotion-focus-bg;
    outline: $padamariotion-focus-outline;
    box-shadow: $padamariotion-focus-box-shadow;
  }
}

.page-item {
  &:not(:first-child) .page-link {
    margin-left: $padamariotion-margin-start;
  }

  &.active .page-link {
    z-index: 3;
    color: $padamariotion-active-color;
    @include gradient-bg($padamariotion-active-bg);
    border-color: $padamariotion-active-border-color;
  }

  &.disabled .page-link {
    color: $padamariotion-disabled-color;
    pointer-events: none;
    background-color: $padamariotion-disabled-bg;
    border-color: $padamariotion-disabled-border-color;
  }
}


//
// Sizing
//
@include padamariotion-size($padamariotion-padding-y, $padamariotion-padding-x, null, $padamariotion-border-radius);

.padamariotion-lg {
  @include padamariotion-size($padamariotion-padding-y-lg, $padamariotion-padding-x-lg, $font-size-lg, $padamariotion-border-radius-lg);
}

.padamariotion-sm {
  @include padamariotion-size($padamariotion-padding-y-sm, $padamariotion-padding-x-sm, $font-size-sm, $padamariotion-border-radius-sm);
}
